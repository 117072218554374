<template>
    <nav class="navbar align-center justify-between">
        <router-link to="/home" class="navbar-logo background"></router-link>
        <div class="navbar-menu justify-center no-wrap">
            <router-link to="/home" exact-active-class="active" class="text-menu"><span>首页</span></router-link>
            <router-link to="/ai-assistant" exact-active-class="active" class="text-menu">企业AI助理</router-link>
            <router-link to="/industry-solutions" exact-active-class="active" class="text-menu">行业解决方案</router-link>
            <router-link to="/partner-recruitment" exact-active-class="active" class="text-menu">共创伙伴招募</router-link>
            <router-link to="/about-us" exact-active-class="active" class="text-menu">关于我们</router-link>
            <!-- <router-link to="/contact-us" exact-active-class="active" class="text-menu">联系获取方案</router-link> -->
        </div>
        <div class="navbar-link flex-col">
            <p @click="clickAlert">联系我们</p>
        </div>
    </nav>
    <div class="sider flex-col align-center" @click="clickAlert">
        <img src="./assets/img/kefu.png" alt="">
        <p>联</p>
        <p>系</p>
        <p>客</p>
        <p>服</p>
    </div>
    <div class="alert" v-show="alertVisible">
        <div class="main">
            <div class="left">
                <iframe
                    src="https://work.weixin.qq.com/ca/cawcdec9a619deb17c"></iframe>
                <!-- <div class="title" @click.stop>
                    <span>添加商务总监微信获取方案</span>
                </div>
                <div class="content" @click.stop>
                    <div class="head">
                        <img src="./assets/img/weixin1.png" alt="">
                    </div>
                    <span>扫码加我微信</span>
                </div> -->
            </div>
            <div class="right">
                <img src="./assets/img/close.png" alt="" @click="clickAlert">
            </div>
        </div>
    </div>
    <!-- <div class="alert" v-show="alertVisible">
        <div class="main">
            <div class="left">
                <div class="title" @click.stop>
                    <span>欢迎和我沟通获取方案</span>
                </div>
                <div class="content" @click.stop>
                    <div class="head">
                        <img src="./assets/img/weixin2.png" alt="">
                    </div>
                    <span>扫码加我微信</span>
                </div>
            </div>
            <div class="right">
                <img src="./assets/img/close.png" alt="" @click="clickAlert">
            </div>
        </div>
    </div> -->
</template>

<script>
export default {
    name: 'TheNavbar2PC',
    data() {
        return {
            alertVisible: false
        }
    },
    methods: {
        clickAlert() {
            this.alertVisible = !this.alertVisible;
        }
    }
};
</script>

<style scoped lang="less" src="./assets/index.less" />