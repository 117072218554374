<template>
    <footer>
        <div class="part_1">
            <img class="img1" src="./assets/img/fo_logo.png" alt="">
            <div class="right">
                <div class="box">
                    <div class="wx" v-show="wx1">
                        <img src="./assets/img/weixin1.png" alt="">
                    </div>
                    <div class="block" @click="showwx1">
                        <div class="img2 background"></div>
                    </div>
                    <span>客服微信</span>
                </div>
                <div class="box" >
                    <div class="wx" v-show="wx2">
                        <img src="./assets/img/weixin2.png" alt="">
                    </div>
                    <div class="block" @click="showwx2">
                        <div class="img3 background"></div>
                    </div>
                    <span>顾问微信</span>
                </div>
            </div>
        </div>
        <br>
        <div class="part_2">
            <div class="box align-center">
                <img src="./assets/img/fo_address.png" alt="" class="img1">
                <span class="span1">北京市西城区阜成门外大街2号</span>
                <img src="./assets/img/jinggongwang.png" alt="" class="img3">
                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11010202010936" rel="noreferrer" target="_blank">京公网安备11010202010936</a>
                <a href="https://beian.miit.gov.cn/" target="_blank"><span>京ICP备2023019473号-2</span> ></a>
                <img src="./assets/img/fo_xian.png" alt="" class="img2">
            </div>
        </div>
    </footer>
</template>

<script>
// 导出组件
export default {
    name: 'FooterPC',
    data() {
        return {
            wx1: false,
            wx2: false
        }
    },
    methods: {
        showwx1() {
            this.wx1 = !this.wx1;
            this.wx2 = false;
        },
        showwx2() {
            this.wx2 = !this.wx2;
            this.wx1 = false;
        },
        close() {
            this.wx1 = false;
            this.wx2 = false;
        }
    }
};
</script>
<style scoped lang="less" src="./assets/index.less" />
