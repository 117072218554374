<template>
    <footer>
        <div class="part_1">
            <img class="img1" src="./assets/img/fo_logo.png" alt="">
            <div class="right">
                <div class="box">
                    <div class="block">
                        <div class="img2 background"></div>
                    </div>
                    <span>客服微信</span>
                </div>
                <div class="box">
                    <div class="block">
                        <div class="img3 background"></div>
                    </div>
                    <span>顾问微信</span>
                </div>
            </div>
        </div>
        <div class="part_2">
            <div class="box">
                <div class="box1 align-center">
                    <img src="./assets/img/fo_address.png" alt="" class="img1">
                    <span>北京市西城区阜成门外大街2号</span>
                </div>
                <div class="box2 justify-between">
                    <img src="./assets/img/jinggongwang.png" alt="" class="img2">
                    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11010202010936" rel="noreferrer" target="_blank">京公网安备11010202010936</a>
                    <a href="https://beian.miit.gov.cn/" target="_blank"><span>京ICP备2023019473号-2</span> ></a>
                    <!-- <img src="./assets/img/fo_xian.png" alt="" class="img2"> -->
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
// 导出组件
export default {
    name: 'FooterMobile',
};
</script>
<style scoped lang="less" src="./assets/index.less" />
