import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView/index.vue'),
    meta: {
      title: '启融AI官网首页',
      keywords: '启融AI, 人工智能,AI,RPA,智能化,自动化,机器人,流程自动化,业务自动化,数字化,解决方案,智能客服,机器学习,chatgpt,GPT,通义千问,百度,文心一言,kimi,AI助理,销售助理,运营助理,新媒体助理,降本增效,数字员工,岗位助理,工作助理',
      description: '首页描述'
    }
  }, {
    path: '/ai-assistant',
    name: 'aiassistant',
    component: () => import('../views/AiAssistant/index.vue'),
    meta: {
      title: '企业AI助理',
      keywords: '岗位AI助理, 人工智能,AI,RPA,智能化,自动化,机器人,流程自动化,业务自动化,数字化,解决方案,智能客服,机器学习,chatgpt,GPT,通义千问,百度,文心一言,kimi,AI助理,销售助理,运营助理,新媒体助理,降本增效,数字员工,岗位助理,工作助理',
      description: '企业AI助理'
    }
  }, {
    path: '/industry-solutions',
    name: 'industrysolutions',
    component: () => import('../views/IndustrySolutions/index.vue'),
    meta: {
      title: 'AI+行业解决方案',
      keywords: 'AI行业解决方案, 人工智能,AI,RPA,智能化,自动化,机器人,流程自动化,业务自动化,数字化,解决方案,智能客服,机器学习,chatgpt,GPT,通义千问,百度,文心一言,kimi,AI助理,销售助理,运营助理,新媒体助理,降本增效,数字员工,岗位助理,工作助理',
      description: 'AI+行业解决方案'
    }
  }, {
    path: '/partner-recruitment',
    name: 'partnerrecruitment',
    component: () => import('../views/PartnerRecruitment/index.vue'),
    meta: {
      title: '行业合作伙伴',
      keywords: '生态,行业合作伙伴, 人工智能,AI,RPA,智能化,自动化,机器人,流程自动化,业务自动化,数字化,解决方案,智能客服,机器学习,chatgpt,GPT,通义千问,百度,文心一言,kimi,AI助理,销售助理,运营助理,新媒体助理,降本增效,数字员工,岗位助理,工作助理',
      description: 'AI+行业合作伙伴'
    }
  }, {
    path: '/about-us',
    name: 'aboutus',
    component: () => import('../views/AboutUs/index.vue'),
    meta: {
      title: '关于启融AI',
      keywords: '生态,行业合作伙伴, 人工智能,AI,RPA,智能化,自动化,机器人,流程自动化,业务自动化,数字化,解决方案,智能客服,机器学习,chatgpt,GPT,通义千问,百度,文心一言,kimi,AI助理,销售助理,运营助理,新媒体助理,降本增效,数字员工,岗位助理,工作助理',
      description: '关于启融AI'
    }
  }, {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('../views/ContactUs.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// 全局前置守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    next('/home');
  } else {
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    next();
  }
});
// 滚动行为
router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default router
