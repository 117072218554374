<template>

  <head>
    <meta name="keywords" content="启融AI, 人工智能,AI,RPA,智能化,自动化,机器人,流程自动化,业务自动化,数字化,解决方案,智能客服,机器学习,chatgpt,GPT,通义千问,百度,文心一言,kimi,AI助理,销售助理,运营助理,新媒体助理,降本增效,数字员工,岗位助理,工作助理">
  </head>
  <nav>
    <!-- 导航栏 -->
    <component :is="navbarComponent" />
  </nav>
  <!-- 路由视图 -->
  <router-view />
  <!-- 弹窗组件 -->
  <component :is="alertComponent" />
  <!-- 底部栏 -->
  <footer>
    <component :is="footerComponent" />
  </footer>
</template>

<script>
// 导入组件
import TheNavbar2PC from './components/Navbar-pc/index.vue';
import TheNavbar2Mobile from './components/Navbar-mobile/index.vue';
import TheFooter2PC from './components/Footer-pc/index.vue';
import TheFooter2Mobile from './components/Footer-mobile/index.vue';
import TheAlert2PC from './components/Alert-pc/index.vue';
import TheAlert2Mobile from './components/Alert-mobile/index.vue'; // 假设你有一个移动端的弹窗组件

export default {
  name: 'App',
  data() {
    return {
      // 导航组件
      navbarComponent: window.innerWidth <= 750 ? TheNavbar2Mobile : TheNavbar2PC,
      // 底部栏组件
      footerComponent: window.innerWidth <= 750 ? TheFooter2Mobile : TheFooter2PC,
      // 弹窗组件
      alertComponent: window.innerWidth <= 750 ? TheAlert2Mobile : TheAlert2PC
    };
  },
  mounted() {
    // 监听窗口大小变化事件，以更新组件
    window.addEventListener('resize', this.updateComponents);
  },
  beforeUnmount() {
    // 移除事件监听
    window.removeEventListener('resize', this.updateComponents);
  },
  methods: {
    updateComponents() {
      this.navbarComponent = window.innerWidth <= 750 ? TheNavbar2Mobile : TheNavbar2PC;
      this.footerComponent = window.innerWidth <= 750 ? TheFooter2Mobile : TheFooter2PC;
      this.alertComponent = window.innerWidth <= 750 ? TheAlert2Mobile : TheAlert2PC;
    }
  }
}
</script>