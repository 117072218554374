<template>
    <a href="https://work.weixin.qq.com/ca/cawcdec9a619deb17c" class="sider flex-col align-center">
        <img src="./assets/img/kefu.png" alt="">
        <p>商</p>
        <p>务</p>
        <p>咨</p>
        <p>询</p>
    </a>
    <!-- <div class="alert" v-show="alertVisible">
        <div class="main">
            <div class="left">
                <div class="title" @click.stop>
                    <span>欢迎和我沟通获取方案</span>
                </div>
                <div class="content" @click.stop>
                    <div class="head">
                        <img src="./assets/img/weixin.png" alt="">
                    </div>
                    <span>扫码加我微信</span>
                </div>
            </div>
            <div class="right">
                <img src="./assets/img/close.png" alt="" @click="clickAlert">
            </div>
        </div>
    </div> -->
</template>

<script>
// 导出组件
export default {
    name: 'AlertMoblie',
    data() {
        return {
            alertVisible: false
        }
    },
    methods: {
        clickAlert() {
            this.alertVisible = !this.alertVisible;
        }
    }
};
</script>
<style scoped lang="less" src="./assets/index.less" />
