<template>
    <nav class="navbar align-center justify-between">
        <router-link to="/home" class="navbar-logo background"></router-link>
        <div class="navbar-small-menu">
            <img class="img-menu" v-show="!menuVisible" src="./assets/img/menu.png" alt="" @click="clickMenu">
            <img class="img-close" v-show="menuVisible" src="./assets/img/close.png" alt="" @click="clickMenu">
        </div>
    </nav>
    <div class="menu-alert" v-show="menuVisible" @wheel.prevent="disableScroll" @click="clickMenu">
        <div class="menu-list flex-col" @click.stop>
            <router-link to="/home" exact-active-class="active" class="text-menu ">首页</router-link>
            <router-link to="/ai-assistant" exact-active-class="active" class="text-menu ">企业AI助理</router-link>
            <router-link to="/industry-solutions" exact-active-class="active" class="text-menu ">行业解决方案</router-link>
            <router-link to="/partner-recruitment" exact-active-class="active" class="text-menu ">共创伙伴招募</router-link>
            <router-link to="/about-us" exact-active-class="active" class="text-menu ">关于我们</router-link>
            <!-- <router-link to="/contact-us" exact-active-class="active" class="text-menu ">联系获取方案</router-link> -->
        </div>
    </div>

</template>

<script>
// 导出组件
export default {
    name: 'TheNavbar2Mobile',
    data() {
        return {
            menuVisible: false
        };
    },
    methods: {
        clickMenu() {
            this.menuVisible = !this.menuVisible;
        }
    }
};
</script>
<style scoped lang="less" src="./assets/index.less" />
